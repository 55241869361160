var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"MapView"},[_c('l-map',{ref:"map",staticClass:"map",attrs:{"center":_vm.center,"zoom":6,"options":{
               mapTypeControl: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUi: false,
               clickableIcons: false,
            }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('v-marker-cluster',_vm._l((_vm.rows),function(row,index){return _c('l-marker',{key:index,attrs:{"lat-lng":_vm.latLng(row)}},[_c('l-popup',[_c('link-like',{on:{"click":function($event){return _vm.goTo(row._id)}}},[_vm._v(_vm._s(row.name))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }