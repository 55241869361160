
import { Component, Watch } from 'vue-property-decorator';
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import { Action, Getter, State } from "vuex-class";
import { IAgencyDB, IBusinessSectorDB, ICompany, ICompanyDB, IUserDB, LabelValue, StoreAction } from "@/types";
import ClientForm from "@/components/client/ClientForm.vue";
import moment from "moment-timezone";
import { ROUTES } from "@/data";
import Visualisations from "@/components/Visualisations.vue";
import ClientActions from "@/components/client/ClientActions.vue";
import { mixins } from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import Tile from "@/components/tileContent/Tile.vue";
import CompanyTile from "@/components/tileContent/CompanyTile.vue";
import MapView from "@/components/MapView.vue";
import Dropzone from "@/components/Dropzone.vue";
import InlineField from "@/components/InlineField.vue";
import LockedAction from '@/components/modal/LockedAction.vue';
import { showModal } from '@/helpers/callables';
import PaginationServer from "@/components/PaginationServer.vue";
import NewIcon from '@/components/NewIcon.vue';

@Component({
    name: "Clients",
    components: {
        MapView,
        CompanyTile,
        Tile,
        ClientActions,
        Visualisations,
        ClientForm,
        ViewConfigurator,
        InlineField,
        Dropzone,
        LockedAction,
        PaginationServer,
        NewIcon,
    }
})
export default class Clients extends mixins(PageMixin) {
    $refs!: any;
    companies: any = [];
    loading = false;
    showLockedAction = false;
    errors: any = {};
    globalErrors: any = [];
    mode = 'list';
    timeout: any = 0;
    totalRecords = 0;
    newClient: ICompany = {
        logo: '',
        name: "",
        addresses: [],
        contacts: [],
        businessSectorId: '',
        numbers: [],
        agencyId: ''
    }
    filters: any = {};
    columns: any = [];
    selectedCompanies: ICompanyDB[] = [];
    viewFilters: any = {
        search: "",
        businessSector: "",
        zip: "",
    };

    visualisations: any = {
        view: 'clients',
        mode: 'list'
    };

    file: any = null;
    loadErrors: any = [];
    uploadResults: any = null;
    pageTitle = this.$t('page.clients.title');

    dataImportEvoliaCompany = {
        evoliaId: '',
        error: '',
        step: 0,
        companyData: {
            email: '',
            phone: '',
            name: '',
            city: '',
            postalCode: '',
        }
    };

    @Action('actions/createCompany') saveCompany!: StoreAction;
    @Action('actions/loadCompanies') loadCompanies!: StoreAction;
    @Action('actions/sendCompaniesDocument') sendCompaniesDocument!: StoreAction;
    @Action('actions/getEvoliaCompany') getEvoliaCompany!: StoreAction;
    @Action('actions/importEvoliaCompany') importEvoliaCompany!: StoreAction;

    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('isInteraction') isInteraction!: boolean;
    @Getter('isInteractionLimited') isInteractionLimited!: boolean
    @Getter('isAuthorizedAgency') isAuthorizedAgency!: boolean
    @Getter('hasSalesforce') hasSalesforce!: boolean;

    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];
    @State('selectedDomains') selectedDomains!: IBusinessSectorDB[];
    @State('superAdmin') superAdmin!: boolean;
    @State('currentAgency') currentAgency!: IAgencyDB;

    @Watch('selectedCollaborators', {deep: true}) onCollabs() {
        this.timedSearch();
    }

    @Watch('selectedDomains', {deep: true}) onDomains() {
        this.timedSearch();
    }

    @Watch('viewFilters', {deep: true}) onViewFilters() {
        this.timedSearch();
    }

    @Watch('visualisations', {deep: true}) onVisu() {
        this.timedSearch();
    }

    @Watch('visualisations.mode') onMode(n: string, o: string) {
        if (n !== o) {
            this.companies = [];
            this.loading = true;
        }
    }

    get listMode() {
        return this.mode === 'list';
    }

    get isListViewMode() {
        return this.visualisations.mode === "list";
    }

    get isGridViewMode() {
        return this.visualisations.mode === "grid";
    }

    get isMapViewMode() {
        return this.visualisations.mode === "map";
    }

    get addMode() {
        return this.mode === 'add';
    }

    get headers() {
        return [
            {
                field: "_id",
                hidden: true
            },
            ...this.columns.map((column: any) => {
                return {
                    label: this.$t('page.clients.table.' + column.id),
                    field: column.id,
                    sortable: column.sortable,
                    formatFn: this.formatCell(column.id),
                    filterOptions: {
                        enable: column.filterable,
                    }
                }
            }),
        ];
    }

    get gridModeFilter() {
        return { "offset": 0, "limit": 50, "sort": { "field": "name", "type": "asc" }, "viewFilters": {}, "view": "clients", "mode": "grid" }
    }

    get rows() {
        return this.companies || []
    }

    get sortOptions() {
        return {
            enabled: true,
            initialSortBy: {
                field: 'name',
                type: 'asc'
            }
        };
    }

    timedSearch(params?: any) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init(params);
        }, 300);
    }

    formatCell(key: string) {
        return (value: any) => {
            if (Array.isArray(value)) {
                return value.join(', ');
            }
            if (typeof value === "boolean") {
                return value ? this.$t('global.yes') : this.$t('global.no');
            }
            if (typeof value === "number" && key === "lastMission") {
                return moment(value).format(<string>this.$t('date.format'));
            }
            if (key === "name") {
                return value;
            }
            return value;
        }
    }

    closeTooltip() {
        this.$refs.main.$el.click();
    }

    companyAction_viewDetail(company: ICompanyDB) {
        return (event: any) => {
            this.$router.push({
                name: ROUTES.APP.CLIENT,
                params: {companyId: company._id}
            });
            this.closeTooltip();
        }
    }

    companyAction_bindWallet(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_bindCollab(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_disable(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_remove(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_sendMessage(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_print(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_export(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_bindFishTank(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }

    companyAction_shareList(companys: ICompanyDB[]) {
        return (event: any) => {
            this.closeTooltip();
        }
    }


    companyActions(index: number) {
        const company: ICompanyDB = this.companies[index];

        return {
            viewDetails: this.companyAction_viewDetail(company),
            bindWallet: this.companyAction_bindWallet([company]),
            bindCollab: this.companyAction_bindCollab([company]),
            disable: this.companyAction_disable([company]),
            remove: this.companyAction_remove([company]),
        }
    }

    groupedActions() {
        return {
            bindFishTank: this.companyAction_bindFishTank(this.selectedCompanies),
            bindCollab: this.companyAction_bindCollab(this.selectedCompanies),
            shareList: this.companyAction_shareList(this.selectedCompanies),
            print: this.companyAction_print(this.selectedCompanies),
            export: this.companyAction_export(this.selectedCompanies),
            sendMessage: this.companyAction_sendMessage(this.selectedCompanies),
            disable: this.companyAction_disable(this.selectedCompanies),
            remove: this.companyAction_remove(this.selectedCompanies),
        }
    }

    onSelectedRowsChange(companies: ICompanyDB[]) {
        this.selectedCompanies = companies;
    }

    toggleMode() {
        if (this.addMode) {
            this.mode = 'list';
        }
        else {
            this.mode = "add";
        }
    }

    onModifier(modifiers: any) {
        if (this.timeout && !modifiers) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
        this.init(modifiers);
        }, 300);
    }

    lineClicked(row: any) {
        this.$router.push({
            name: ROUTES.APP.CLIENT,
            params: {companyId: row.row._id}
        })
    }

    displayLock() {
        showModal.call(this, 'LockedAction');
    }

    displayImportEvoliaCompany() {
        showModal.call(this, 'CompanyModalImportFromEvolia');
    }

    async submitImportEvoliaCompany() {
        this.loading = true;
        if(this.dataImportEvoliaCompany.step === 0) {
            try {
                this.dataImportEvoliaCompany.companyData = await this.getEvoliaCompany({
                    evoliaId: this.dataImportEvoliaCompany.evoliaId,
                });

                this.dataImportEvoliaCompany.step++;
                this.loading = false;
                return;
            } catch (e) {
                console.log(e);
                console.log((Object.values(e.errors) as any)[0].id);
                this.dataImportEvoliaCompany.error = (Object.values(e.errors) as any)[0];
                this.loading = false;
                return;
            }
        }

        if(this.dataImportEvoliaCompany.step === 1) {
            try {
                await this.importEvoliaCompany({
                    customerId: this.dataImportEvoliaCompany.evoliaId,
                });
                this.$modal.hide('CompanyModalImportFromEvolia');
                this.$toast.open({
                    message: this.$t('page.client.importedCompany') as string,
                    type: "success",
                    duration: 10000
                });
                this.init();
                this.loading = false;
                return;
            } catch(e) {
                console.log(e);
                this.$toast.open({
                    message: this.$t('errors.Global.ServerError._default') as string,
                    type: "error",
                    duration: 10000
                });
            this.loading = false;
                this.loading = false;
                return;
            }
        }
    }

    closeImportEvoliaCompany() {
        this.dataImportEvoliaCompany = {
            evoliaId: '',
            error: '',
            step: 0,
            companyData: {
                email: '',
                phone: '',
                name: '',
                city: '',
                postalCode: '',
            }
        };
    }

    async upload() {
        this.loading = true;
        this.loadErrors = [];
        this.uploadResults = null;
        try {
            this.uploadResults = await this.sendCompaniesDocument({
                data: this.file
            });
            this.file = "";
            await this.init({ currentPage: this.filters.currentPage, currentPerPage: this.filters.currentPerPage });
            this.$forceUpdate();
        } catch (e) {
            console.log(e);
            this.loadErrors = [{id: "Invalid.Document"}];
        }
        this.loading = false;
    }

    downloadModel() {
        this.$refs.link.click();
    }

    get formattedViewFilters() {
        return {
            search: this.viewFilters.search || undefined,
            zip: this.viewFilters.zip || undefined,
            jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
            businessSector: (this.viewFilters.businessSector?.length && this.viewFilters.businessSector.map((j: LabelValue) => j.value)) || undefined,
            hasVehicle: this.viewFilters.hasVehicle === 'yes' ? true : this.viewFilters.hasVehicle === 'no' ? false : undefined,
            hasNightAvailable: this.viewFilters.hasNightAvailable === 'yes' ? true : this.viewFilters.hasNightAvailable === 'no' ? false : undefined,
            hasWeekendAvailable: this.viewFilters.hasWeekendAvailable === 'yes' ? true : this.viewFilters.hasWeekendAvailable === 'no' ? false : undefined,
        }
    }

    registerView(modelName: string) {
        this.$emit('registerView', {
            modelName,
            filters: this.formattedViewFilters,
            view: this.visualisations.view,
            mode: this.visualisations.mode
        });
    }

    eraseView(selectedView: any) {
        this.$emit('eraseView', {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: this.formattedViewFilters,
            view: this.visualisations.view,
            mode: this.visualisations.mode
        });
    }

    async saveNewClient() {
        this.loading = true;
        try {
            if(this.newClient.siret) this.newClient.siret = this.newClient.siret.replace(/\s/g, '');
            const result: any = await this.saveCompany(this.newClient);
            await this.$router.push({
                name: ROUTES.APP.CLIENT,
                params: {companyId: result._id}
            });
        }
        catch (e) {
            this.errors = e.errors;
            if(e.fullErr?.response?.status === 422) {
                if(e.fullErr.response.data[0]["id"] == "Siret.NotValid") {
                    // TODO: passer l'erreur au niveau local du siret.
                    this.globalErrors.push("Numéro Siret invalide: il doit contenir 14 chiffres sans espace ou resté vide.");
                }
            }
            if(e.fullErr?.response?.status === 400) {
                if((e.fullErr.response.data[0].id == "cant.create") && (e.fullErr.response.data[0].context == "contractmanager")) {
                    this.globalErrors.push({id: "Client.ContractManager", key: "cantCreate"});
                    this.$toast.open({
                        position: "top-right",
                        message: this.$t("errors.Client.ContractManager.cantCreate").toString(),
                        type: "error",
                        duration: 10000
                    });
                }
            }
            console.log(e);
        }
        this.loading = false;
    }

    async init(modifiers?: any) {
        this.loading = true;
        try {
            if (modifiers) {
                this.filters = {
                    offset: (modifiers.currentPage - 1) * modifiers.currentPerPage,
                    currentPage: modifiers.currentPage,
                    currentPerPage: modifiers.currentPerPage,
                    limit: modifiers.currentPerPage,
                    sort: modifiers.sort,
                    columnFilters: modifiers.columnFilters && ({
                        ...modifiers.columnFilters,
                        businessSectorId: modifiers.columnFilters.businessSector
                    }) || undefined,
                };

                if (this.filters.columnFilters?.businessSector) {
                    delete this.filters.columnFilters.businessSector;
                }
            } else {
                this.filters = {
                    ...this.filters,
                    currentPage: 1,
                    currentPerPage: 50,
                }
            }

            this.filters.viewFilters = this.formattedViewFilters;
            this.filters.view = this.visualisations.view;
            this.filters.mode = this.visualisations.mode;

            if (this.filters.mode === "grid") {
                this.filters.mode = "list";
            }

            const result: any = await this.loadCompanies(this.filters);

            this.columns = result.columns;
            this.companies = result.rows;

            this.totalRecords = result.pagination.total;
        } catch (e) {
            this.errors = e;
            console.log(e);
        }
        this.loading = false;
    }

    goToClient(clientId: string) {
        this.$router.push({
            name: ROUTES.APP.CLIENT,
            params: {companyId: clientId}
        });
    }

    clickAction(other:any) {
        this.isInteractionLimited ? this.displayLock() : other()
    }

    beforeMount() {
        this.timedSearch({
            currentPage: 1,
            currentPerPage: 50,
            sort: {
                field: 'name',
                type: 'asc'
            }
        });
    }
}
