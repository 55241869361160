
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {ROUTES} from "@/data";
import {Getter, State} from "vuex-class";
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
    name: "MapView",
    components : {
        LMap, LTileLayer, LMarker, LPopup,
        'v-marker-cluster': Vue2LeafletMarkerCluster
    }
})
export default class MapView extends Vue {
    $refs!: {
        map: any
    };
    @Prop() rows!: any;

    @State('currentAgency') currentAgency!:any;

    center:any = {lat: 46.977391, lng: 0.6928313};

    url:string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

    attribution:string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'

    @Watch('$refs', {deep: true})
    onMap() {
        console.log("in refs")
        this.boundMap();
    }

    @Watch('rows', {deep: true})
    onRows() {
        this.boundMap();
    }

    get agencyLocation() {
        return this.currentAgency?.address?.location && {
            latitude: this.currentAgency.address.location[1],
            longitude: this.currentAgency.address.location[0]
        };
    }

    get isClients() {
        return this.$route.name === ROUTES.APP.CLIENTS;
    }

    get isInterims() {
        return this.$route.name === ROUTES.APP.INTERIMS;
    }

    get markerIcon() {
        return {
            url: require('@/assets/icons/mapMarker2.svg'),
            size: {width: 34, height: 50, f: 'px', b: 'px'},
            scaledSize: {width: 34, height: 50, f: 'px', b: 'px',}
        }
    }

    latLng(position:any) {
        return position && position.latitude && position.longitude && {
            lat: parseFloat(<string>position.latitude?.toString()),
            lng: parseFloat(<string>position.longitude?.toString())
        };
    }

    boundMap() {
        console.log("want to bound");
        // @ts-ignore;
        if (window.google && this.rows?.length) {
            // @ts-ignore;
            const bounds = new google.maps.LatLngBounds();
            let pushed = 0;

            this.rows.forEach((marker: any) => {
                if (marker.latitude && marker.longitude) {
                    pushed += 1;
                    bounds.extend(this.latLng(marker));
                }
            });

            console.log('pushed', pushed);
            if (pushed && bounds && this.$refs.map) {
                console.log('fit');
                this.$refs.map.fitBounds(bounds);
                this.$refs.map.panToBounds(bounds);
            }
        }
    }

    goTo(id: string) {
        if (this.isClients) {
            this.$router.push({name: ROUTES.APP.CLIENT, params: {companyId: id}});
        }
        if (this.isInterims) {
            this.$router.push({name: ROUTES.APP.INTERIM, params: {interimId: id}});
        }
    }

    mounted() {
        this.boundMap()
    }

}
