
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component({
        name: "ClientActions"
    })
    export default class ClientActions extends Vue {
        @Prop(String) mode!: string;

        get isGroupMode() {
            return this.mode === "group";
        }
    }
