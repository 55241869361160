
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import VModel from "@/mixins/VModel.vue";
    import {LabelValue} from "@/types";

    @Component({
        name: "PaginationServer"
    })
    export default class PaginationServer extends mixins(VModel) {
        @Prop() totalRecords!: number;

        $refs!: {
            totalPage: any
        };
        get limitOptions() {
            return [
                {
                    label: 10,
                    value: 10
                },
                {
                    label: 20,
                    value: 20
                },
                {
                    label: 30,
                    value: 30
                },
                {
                    label: 40,
                    value: 40
                },
                {
                    label: 50,
                    value: 50
                }
            ];
        }

        get totalPage() {
            const value = Math.floor(this.totalRecords / (this.innerValue.limit));
            const ret = Math.floor(this.totalRecords % (this.innerValue.limit));

            if (isNaN(value)) {
                return 1;
            }
            return ret !== 0 ? value + 1 : value;
        }

        get pagesOptions() {
            let i = 0, len = this.totalPage;
            const arr: LabelValue[] = [];

            while (i < len) {
                arr.push({
                    label: (i + 1).toString(),
                    value: (i + 1).toString(),
                    selected: i + 1 === this.innerValue.currentPage
                })
                ++i;
            }

            return arr;
        }

        get onTotal() {
            return this.innerValue.limit * (this.innerValue.currentPage)
        }

        manualChange(value:string) {
            this.innerValue.currentPage = parseInt(value);
            this.$emit('change', {
                currentPage: this.innerValue.currentPage,
                offset: (this.innerValue.currentPage - 1) * this.innerValue.currentPerPage,
                currentPerPage: this.innerValue.currentPerPage,
            });
        }

        change() {
            this.$emit('change', {
                currentPage: this.innerValue.currentPage,
                offset: (this.innerValue.currentPage - 1) * this.innerValue.currentPerPage,
                currentPerPage: this.innerValue.currentPerPage,
            });
        }

        previous()  {
            if (this.innerValue.currentPage > 1) {
                this.innerValue.currentPage -= 1;
                this.change();
            }
        }

        next() {
            if (this.innerValue.currentPage < this.totalPage) {
                this.innerValue.currentPage += 1;
                this.change();
            }
        }
    }
